export const framed = true
export const partner = 'sin'
export const overridePerms = true
export const authed = false
export const customPresets = true

// CSS Overrides
export const overridePrimary = '#001DC8'
export const overrideBackground = '#1D1D1F'
export const overrideBackgroundSecondary = 'linear-gradient(0deg,#24272b,#3d4148 120%)'
export const overrideColor = '#e0e0e3'
export const overrideSecondary = '#999'
export const overrideCheckboxColor = '#818181'
export const overrideFont = 'Red Hat Display'
export const overridePlayerText = '#fff'
export const overrideButtonText = '#fff'
export const overrideTableHighlight = "#41464f"

export const allowCheatsheets = true
