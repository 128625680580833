import { Radio } from "antd"
import styled from 'styled-components'

const FilledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper-checked {
    color: white !important;
    background: #031cad;
  }

  @media (max-width: 991px) {
    margin-top: 10px;
  }
`

export default FilledRadioGroup