import React from 'react'
import { Dropdown, Space, Select } from 'antd'
import { weekOptions, seasonOptions } from '../utils/nfl-week'
import { CaretDownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { getSiteImg } from '../utils/team-images-loader'

const DrowdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const SmallCarrot = styled.span`
  font-size: 10px;
  margin-left: -5px;
`

const siteToLogoMap = {
  'dk': (
    getSiteImg('dk')
  ),
  'fd': (
    getSiteImg('fd')
  )
}

const NFLWeekPicker = ({ selectedWeek, selectedSeason, changeDateInfo, site, updateSite }) => {
  const _siteOptions = Object.keys(siteToLogoMap).map(site => {
    return {
      label: <span style={{"padding": "2px"}}>{siteToLogoMap[site]}</span>,
      value: site
    }
  })

  const handleChange = (week, season) => {
    changeDateInfo({counter: week, season})
  }

  return (
    <DrowdownContainer>
      <Select
        style={{marginRight: '5px'}}
        value={site}
        onChange={(s) => {updateSite(s)}}
        options={_siteOptions}
      />
      <Select
        style={{marginRight: '5px'}}
        value={selectedSeason}
        onChange={(season) => {handleChange(selectedWeek, season)}}
        options={seasonOptions.map(season => {
          return {
            label:`${season}`,
            value: season
          }
        })}
      />
      <Select
        style={{width: '60px', marginRight: '5px'}}
        value={selectedWeek}
        onChange={(week) => {handleChange(week, selectedSeason)}}
        options={weekOptions.map(week => {
          return {
            label:`${week}`,
            value: week
          }
        })}
      />
    </DrowdownContainer>
  )
}

export default NFLWeekPicker
